import { useState } from "react";
import Navbar from "./components/Navbar";
import OSM_Buildings_3D from "./components/OSM_Buildings_3D";
import video from "./videos/Pasig River Intro Animation.mp4";

function App() {
	const [mapMode, setMapMode] = useState("video");
	const [videoEnded, setVideoEnded] = useState(false);

	return (
	<div className="App h-screen">
		<Navbar />

    	{/* Content */}
		<div 
			id="main-content"
			style={{paddingTop: "10vh"}}
			className="graphs-container h-screen mx-6 grid grid-cols-2 gap-2"
		>
			{/* COLUMN A */}
			<div className="h-5/6 border-solid border rounded-xl border-white p-4 relative overflow-hidden">

				{/* Insert a video here depending on the videoEnded state */}
				{
					!videoEnded ? (
						<>
							<video autoPlay muted controls disablePictureInPicture
								controlsList="nodownload nofullscreen noremoteplayback"
								className="w-full h-full object-cover absolute top-0 left-0"
								onEnded={(e) => {
									setVideoEnded(true);
								}}
								onD
							>
								<source src={video} type="video/mp4" /> 
								Your browser does not support the video tag. 
							</video>
							<p className="text-xs z-5 absolute bottom-2 right-2">© 2024 by Google Earth Studio</p>
						</>
					) : (
						// <p>3D Map</p>
						<iframe
							title="3D OSM Buildings Map"
							src="https://hquizzagan.github.io/buildings-around-pasig/"
							allowFullScreen 
							className="w-full h-full object-cover absolute top-0 left-0"
						></iframe>
					)
				}
			</div>

			{/* COLUMN B */}
			<div className="h-4/6 p-2">
				<p>Graphs here!</p>
			</div>
		</div>
	</div>
  );
}

export default App;
