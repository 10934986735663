function Navbar() {

  return (
    <div
        style={{height: "10vh"}}
        className="flex justify-start items-center w-screen fixed top-0 left-0 mb-8 px-8 py-4 z-5"
    >
        <span className="text-3xl uppercase">TerraFlow</span>
        <span className="pl-2 italic">by HQuizzagan</span>
    </div>
  )
}

export default Navbar